/* eslint-disable max-len */
import AdminAccounts from 'containers/Admin/Accounts/Loadable';
import AdminAirlines from 'containers/Admin/Airlines/Loadable';
import AdminAirportRoutes from 'containers/Admin/AirportRoutes/Loadable';
import AdminAirports from 'containers/Admin/Airports/Loadable';
import AdminAirportsOffices from 'containers/Admin/AirportsOffices/Loadable';
import AdminAirwayBillNew from 'containers/Admin/AirwayBillNew/Loadable';
import AdminAirwayBills from 'containers/Admin/AirwayBills/Loadable';
import AssignAirwaybills from 'containers/Admin/AssignAirwaybills/Loadable';
import AdminCN33LoadReceptacle from 'containers/Admin/CN33LoadReceptacle/Loadable';
import AdminCN33ReceptacleList from 'containers/Admin/CN33ReceptacleList/Loadable';
import AdminCN33ReceptacleListV2 from 'containers/Admin/CN33ReceptacleList-v2/Loadable';
import AdminDashboard from 'containers/Admin/Dashboard/Loadable';
import AdminDispatchesPending from 'containers/Admin/DispatchesPending/Loadable';
import AdminDispatchList from 'containers/Admin/DispatchList/Loadable';
import AdminDispatchShipped from 'containers/Admin/DispatchShipped/Loadable';
import AdminEditDispatch from 'containers/Admin/EditDispatch/Loadable';
import AdminInternalDashboard from 'containers/Admin/InternalDashboard/Loadable';
import AdminInternalServices from 'containers/Admin/InternalServices/Loadable';
import AdminInternalServicesServices from 'containers/Admin/InternalServicesServices/Loadable';
import Admin from 'containers/Admin/Loadable';
import AccountServicePriceLimits from 'containers/Admin/AccountServicePriceLimits/Loadable';
import AdminLoadDispatch from 'containers/Admin/LoadDispatch/Loadable';
import MoneyRanges from 'containers/Admin/MoneyRanges/Loadable';
import AdminNewAccount from 'containers/Admin/NewAccount/Loadable';
import AdminNewParcel from 'containers/Admin/NewParcel/Loadable';
import EditParcelService from 'containers/Admin/EditParcelService/Loadable';
import AdminNewPickup from 'containers/Admin/NewPickup/Loadable';
import AdminScanPickup from 'containers/Admin/ScanPickup/Loadable';
import AdminNewUser from 'containers/Admin/NewUser/Loadable';
import AdminParcelImports from 'containers/Admin/ParcelImports/Loadable';
import AdminParcelDLPacketImports from 'containers/Admin/ParcelImportsDLPacket/Loadable';
import AdminParcels from 'containers/Admin/Parcels/Loadable';
import AdminParcelScan from 'containers/Admin/ParcelScan/Loadable';
import AdminParcelScanV3 from 'containers/Admin/ParcelScan-V3/Loadable';
import AdminOriginScan from 'containers/Admin/OriginScan/Loadable';
import AdminParcelsTaxedPaid from 'containers/Admin/ParcelsTaxedPaid/Loadable';
import AdminParcelsTaxedPending from 'containers/Admin/ParcelsTaxedPending/Loadable';
import AdminParcelsUndeliverable from 'containers/Admin/ParcelsUndeliverable/Loadable';
import AdminParcelTools from 'containers/Admin/ParcelTools/Loadable';
import AdminPickups from 'containers/Admin/Pickups/Loadable';
import RatesCountries from 'containers/Admin/RatesCountries/Loadable';
import RatesPostalCodes from 'containers/Admin/RatesPostalCodes/Loadable';
import RatesServiceProviders from 'containers/Admin/RatesServiceProviders/Loadable';
import RatesServices from 'containers/Admin/RatesServices/Loadable';
import RatesStates from 'containers/Admin/RatesStates/Loadable';
import AdminServiceCountries from 'containers/Admin/ServiceCountries/Loadable';
import ServiceDeposits from 'containers/Admin/ServiceDeposits/Loadable';
import AdminServiceOptions from 'containers/Admin/ServiceOptions/Loadable';
import AdminServiceRouteLegs from 'containers/Admin/ServiceRouteLegs/Loadable';
import AdminServiceRoutes from 'containers/Admin/ServiceRoutes/Loadable';
import AdminServices from 'containers/Admin/Services/Loadable';
import AdminServiceServicePickup from 'containers/Admin/ServiceServicePickup/Loadable';
import AdminCN33UnitizersInformed from 'containers/Admin/UnitizerInformed/Loadable';
import AdminUsers from 'containers/Admin/Users/Loadable';
import Weights from 'containers/Admin/Weights/Loadable';
import AdminWhiteLabel from 'containers/Admin/WhiteLabel/Loadable';
import App from 'containers/App/App';
import Home from 'containers/Home/Loadable';
import Home2 from 'containers/Home2/Loadable';
import { stringify } from 'query-string';
// import RatesWeightRates from 'containers/Admin/RatesWeightRates/Loadable';
import ComposeCarrierCosts from 'containers/Admin/ComposeCarrierCosts/Loadable';
import ComposeCarrierCostsSimple from 'containers/Admin/ComposeCarrierCostsSimple/Loadable';
import ComposeCarrierCostsSimpleCreate from 'containers/Admin/ComposeCarrierCostsSimpleCreate/Loadable';
import ComposeLastMileCosts from 'containers/Admin/ComposeLastMileCosts/Loadable';
import ComposePricing from 'containers/Admin/ComposePricing/Loadable';
import ComposeWeights from 'containers/Admin/ComposeWeights/Loadable';
import ListPricing from 'containers/Admin/ListPricing/Loadable';
import Markup from 'containers/Admin/Markup/Loadable';
import Tariffs from 'containers/Admin/Tariffs/Loadable';
// import RatesTariffsWeights from 'containers/Admin/RatesTariffsWeights/Loadable';
import ServicesAreasInsuranceCosts from 'containers/Admin/ServicesAreasInsuranceCosts/Loadable';
import ServicesAreasSurchargesCosts from 'containers/Admin/ServicesAreasSurchargesCosts/Loadable';
import ServicesAreasTariffs from 'containers/Admin/ServicesAreasTariffs/Loadable';
import ServicesAreasWeightsCosts from 'containers/Admin/ServicesAreasWeightsCosts/Loadable';
// import RatesServicesAreas from 'containers/Admin/RatesServicesAreas/Loadable';
import RatesServicesProvidersCountries from 'containers/Admin/RatesServicesProvidersCountries/Loadable';
import RatesServicesProvidersDestinations from 'containers/Admin/RatesServicesProvidersDestinations/Loadable';
import RatesServicesRules from 'containers/Admin/RatesServicesRules/Loadable';
import RatesServicesRulesTypes from 'containers/Admin/RatesServicesRulesTypes/Loadable';
// import RatesRates from 'containers/Admin/RatesRates/Loadable';
// import SurchagesTypes from 'containers/Admin/SurchagesTypes/Loadable';
// import SurchagesAirlines from 'containers/Admin/SurchagesAirlines/Loadable';
// import SurchagesTariffs from 'containers/Admin/SurchagesTariffs/Loadable';
// import RatesBillingFactors from 'containers/Admin/RatesBillingFactors/Loadable';
// import RatesBillingTiers from 'containers/Admin/RatesBillingTiers/Loadable';
import AdminAccountHsCodesCategorization from 'containers/Admin/AccountHsCodesCategorization/Loadable';
import AdminAccountServiceRestrictions from 'containers/Admin/AccountServiceRestrictions/Loadable';
import AdminAddress from 'containers/Admin/Address/Loadable';
import AdminAddressDetails from 'containers/Admin/AddressDetails/Loadable';
import AdminAirwayBill from 'containers/Admin/AirwayBill/Loadable';
import AdminColombiaManifest from 'containers/Admin/ColombiaManifest/Loadable';
import AdminCountryRegulations from 'containers/Admin/CountryRegulations/Loadable';
import AdminFacilities from 'containers/Admin/Facilities/Loadable';
import AdminDropoffStations from 'containers/Admin/DropoffStations/Loadable';
import AdminFacilitiesInboundRules from 'containers/Admin/FacilitiesInboundRules/Loadable';
import AdminGoodCategories from 'containers/Admin/GoodCategories/Loadable';
import HarmonizedCodesInterchange from 'containers/Admin/HarmonizedCodesInterchange/Loadable';
import HarmonizedCodes from 'containers/Admin/HarmonizeCodes/Loadable';
import HarmonizedCodesRestrictions from 'containers/Admin/HarmonizeCodesRestrictions/Loadable';
import HarmonizedCodesRestrictionsAuth from 'containers/Admin/HarmonizeCodesRestrictionsAuth/Loadable';
import AdminInvoice from 'containers/Admin/Invoice/Loadable';
import AdminInvoices from 'containers/Admin/Invoices/Loadable';
import AdminDonwloadLabelDispatch from 'containers/Admin/LabelDispatch/Loadable';
import AdminDonwloadLabelParcel from 'containers/Admin/LabelParcel/Loadable';
import AdminDonwloadLabelParcelForScan from 'containers/Admin/LabelParcelForScan/Loadable';
import AdminDonwloadLabelPickup from 'containers/Admin/LabelPickup/Loadable';
import AdminDonwloadLabelUnitizer from 'containers/Admin/LabelUnitizer/Loadable';
import AdminLoginLogs from 'containers/Admin/LogingLogs/Loadable';
import AdminNewStore from 'containers/Admin/NewStore/Loadable';
import AdminQuickbooks from 'containers/Admin/Quickbooks/Loadable';
import AdminRatesCalculator from 'containers/Admin/RatesCalculator/Loadable';
import AdminReports from 'containers/Admin/Reports/Loadable';
import AdminStoreOrders from 'containers/Admin/StoreOrders/Loadable';
import AdminStores from 'containers/Admin/Stores/Loadable';
import AdminTrackingCodes from 'containers/Admin/TrackingCodes/Loadable';
import AdminTrackingCodesCustomers from 'containers/Admin/TrackingCodesCustomers/Loadable';
import AdminTrackingCodesVendors from 'containers/Admin/TrackingCodesVendors/Loadable';
import AdminTrackingEvents from 'containers/Admin/TrackingEvents/Loadable';
import BpsKpi from 'containers/Admin/BpsKpi/Loadable';
import CleanedLocations from 'containers/Admin/CleanedLocations/Loadable';
import CustomAlert from 'containers/Admin/CustomsAlerts/Loadable';
import url from 'url';

/** ADMIN V2 - DataGrid */
import AdminParcelsV2 from 'containers/Admin/Parcels-v2/Loadable';
import AdminNewPickupV2 from 'containers/Admin/NewPickup-v2/Loadable';

import UnitizersDispacthesReportLoadable from 'containers/Admin/UnitizersDispatchesReport/Loadable';
import DispacthesReportLoadable from 'containers/Admin/DispatchesReport/Loadable';

// import RetailerDashboard from 'containers/Retailer/Dashboard/Loadable';
// import RetailerDonwloadLabelParcel from 'containers/Retailer/LabelParcel/Loadable';
// import RetailerDonwloadLabelUnitizer from 'containers/Retailer/LabelUnitizer/Loadable';
// import RetailerDonwloadLabelDispatch from 'containers/Retailer/LabelDispatch/Loadable';
// import RetailerNewParcel from 'containers/Retailer/NewParcel/Loadable';
// import RetailerMyParcel from 'containers/Retailer/MyParcel/Loadable';
// import RetailerParcelImports from 'containers/Retailer/ParcelImports/Loadable';
// import RetailerParcelsUndeliverable from 'containers/Retailer/ParcelsUndeliverable/Loadable';
// import CN33ReceptacleList from 'containers/Retailer/CN33ReceptacleList/Loadable';
// import LoadDispatch from 'containers/Retailer/LoadDispatch/Loadable';
// import DispatchShipped from 'containers/Retailer/DispatchShipped/Loadable';
// import DispatchesPending from 'containers/Retailer/DispatchesPending/Loadable';
// import CustomAlertList from 'containers/Retailer/CustomAlertList/Loadable';
// import CN33LoadReceptacle from 'containers/Retailer/CN33LoadReceptacle/Loadable';
// import Invoices from 'containers/Retailer/Invoices/Loadable';
// import Invoice from 'containers/Retailer/Invoice/Loadable';
// import RetailerUpdateProfile from 'containers/Retailer/UpdateProfile/Loadable';
// import RetailerChangePassword from 'containers/Retailer/ChangePassword/Loadable';
// import RetailerAccounts from 'containers/Retailer/Accounts/Loadable';
// import RetailerNewAccount from 'containers/Retailer/NewAccount/Loadable';
// import RetailerWhiteLabel from 'containers/Retailer/WhiteLabel/Loadable';
import LoadingComponent from 'components/Loaders/LoadingComponent';
import AboutUs from 'containers/AboutUs/Loadable';
import Claims from 'containers/Claims/Loadable';
import ContactUs from 'containers/ContactUs/Loadable';
import Docs from 'containers/Docs/Loadable';
import Fulfilment from 'containers/Fulfilment/Loadable';
import LithiumBatteries from 'containers/LithiumBatteries/Loadable';
import Login from 'containers/Login/Loadable';
import LoginSuccess from 'containers/LoginSuccess/Loadable';
import NotFound from 'containers/NotFound/Loadable';
import ParcelsUndeliverable from 'containers/ParcelsUndeliverable/Loadable';
import Perfumes from 'containers/Perfumes/Loadable';
import Privacy from 'containers/Privacy/Loadable';
import Prohibited from 'containers/Prohibited/Loadable';
import Register from 'containers/Register/Loadable';
import Terms from 'containers/Terms/Loadable';
import Tracking from 'containers/Tracking/Loadable';
import { memo, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useAuth } from 'store';
import { useSelector } from 'react-redux';
import _get from 'lodash.get';
import AdminReceptacleTypeList from './containers/Admin/ReceptacleTypes/Loadable';
import AdminNewReceptacleType from './containers/Admin/NewReceptacleType/Loadable';
import AdminReceptacleList from './containers/Admin/Receptacles/Loadable';
import AdminNewReceptacle from './containers/Admin/NewReceptacle/Loadable';
import AdminEditReceptacle from './containers/Admin/EditReceptacle/Loadable';
import AdminReceptacleDashboard from './containers/Admin/ReceptacleDashboard/Loadable';

import AdminStoreAmazonAuthorization from './containers/Admin/StoreAmazonAuthorization/Loadable';
import AdminStoreAmazonOrders from './containers/Admin/StoreAmazonOrders/Loadable';
import DutiesCostCalculator from './containers/Admin/DutiesCostCalculator/DutiesCostCalculator';
import Unauthorized from './containers/Unauthorised/Unauthorized';
import ClaimsContactForm from './containers/Admin/Claims/ClaimsContactForm';

const LoadingPage = () => {
  return (
    <>
      <style>
        {`
    @keyframes scaleout {
      0% {
        transform: scale(0.0);
      }
      100% {
        opacity: 0;
        transform: scale(1.0);
      }
    }
    @-webkit-keyframes scaleout {
      0% {
        transform: scale(0.0);
      }
      100% {
        opacity: 0;
        transform: scale(1.0);
      }
    }
    @-webkit-keyframes smoothShow {
      0% {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes smoothShow {
      0% {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    `}
      </style>
      <LoadingComponent />
    </>
  );
};

const redirectQueryParamName = 'redirect';
const createRedirectLoc = (allowRedirectBack: any) => (props: any, redirectPath: any) => {
  const { location } = props;
  const redirectLoc = url.parse(redirectPath, true);

  let query;

  if (allowRedirectBack) {
    query = {
      [redirectQueryParamName]: `${location.pathname}${location.search || ''}${
        location.hash || ''
      }`,
    };
  } else {
    query = {};
  }

  query = {
    ...query,
    ...redirectLoc.query,
  };

  return {
    pathname: redirectLoc.pathname,
    hash: redirectLoc.hash || '',
    search: `?${stringify(query)}`,
  };
};

const allowRedirectBackFn = () => true;

const redirect = ({ navigate, ...props }: any, path: any) =>
  navigate(createRedirectLoc(allowRedirectBackFn(props, path))(props, path));

const pathsOnlyAdminAccess = [
  '/admin/reports',
  '/admin/users',
  '/admin/accounts',
  '/admin/harmonized-codes-restrictions-auth',
  '/admin/good-categories',
  '/admin/account-hs-code-categorization',
  '/admin/parcel-scan',
  '/admin/tracking-events',
  '/admin/parcel-tools',
  '/admin/parcels/taxed-pending',
  '/admin/parcels/taxed-paid',
  '/admin/cn33-load-receptacle',
  '/admin/cn33-receptacle-list',
  '/admin/load-dispatch',
  '/admin/dispatch-list',
  '/admin/dispatches-pending',
  '/admin/dispatch-shipped',
  '/admin/airway-bills',
  // '/admin/parcels-undeliverable/processed',
  // '/admin/parcels-undeliverable/action-required',
  // '/admin/parcels-undeliverable/pending',
  '/admin/reports-colombia-manifest',
  '/admin/dispatches-report',
  '/admin/login-logs',
  '/admin/rates-countries',
  '/admin/rates-states',
  '/admin/rates-postalcodes',
  '/admin/service-deposits',
  '/admin/list-pricing',
  '/admin/compose-pricing',
  '/admin/compose-costs',
  '/admin/weights',
  '/admin/money-ranges',
  '/admin/tariffs',
  '/admin/markup',
  '/admin/services-areas-tariffs',
  '/admin/services-areas-weights-costs',
  '/admin/services-areas-insurance-costs',
  '/admin/services-areas-surcharges-costs',
  '/admin/harmonized-codes',
  '/admin/service-service-pickup',
  '/admin/internal-services',
  '/admin/internal-services-services',
  '/admin/services',
  '/admin/service-countries',
  '/admin/service-options',
  '/admin/service-routes',
  '/admin/service-route-legs',
  '/admin/harmonized-codes-restrictions',
  '/admin/rates-services',
  '/admin/rates-service-providers',
  '/admin/rates-services-areas-tariffs',
  '/admin/rates-services-areas',
  '/admin/rates-services-rules',
  '/admin/rates-services-rules-types',
  '/admin/rates-services-providers-destinations',
  '/admin/rates-services-providers-countries',
  '/admin/airport-routes',
  '/admin/airports-offices',
  '/admin/airlines',
  '/admin/airports',
  '/admin/addresses',
  '/admin/address-details',
  '/admin/dropoff-stations',
  '/admin/facilities',
  '/admin/facilities-inbound-rules',
  '/admin/quickbooks',
  '/admin/tracking-codes',
  '/admin/tracking-codes-customers',
  '/admin/tracking-codes-vendors',
  '/admin/account-service-restrictions',
  '/admin/account-service-price-limits',
  '/admin/receptacle-list',
  '/admin/receptacle-type-list',
];

const pathsRoleReadOnlyAccess = ['/admin/parcels'];
const AuthComponent = ({ children, blockUnloggedUsers }) => {
  const userRoles = useSelector((_state) => _get(_state, 'auth.user.roles'));

  const { status } = useAuth({
    shouldUpdate: {
      toJSON: () => {
        return 'route';
      },
      test: (prev, next) => {
        if (
          (next.status === 'authenticated' ||
            next.status === 'not-authenticated' ||
            next.status === 'error') &&
          prev.status !== next.status
        ) {
          return true;
        }
        return false;
      },
    },
  });
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { pathname } = location;
  const pathNameParts = pathname.split('/').filter(Boolean);

  console.log(status, 'status');
  // should redirect to /login

  if (status === 'not-authenticated') {
    window.location.reload();
  }

  useEffect(() => {
    if (status === 'not-authenticated' || status === 'error') {
      redirect({ location, navigate }, params.lang ? `/${params.lang}/login` : '/login');
    }
  }, [status]);

  if (
    (!status || status === 'idle' || status === 'not-authenticated') &&
    (pathNameParts[1] === 'admin' || blockUnloggedUsers)
  ) {
    return <LoadingPage />;
  }

  const readOnlyRole = userRoles?.find((role) => role.role.role === 'read_only');

  if (readOnlyRole) {
    let isOkayToAccessReadOnly = false;

    pathsRoleReadOnlyAccess.forEach((path) => {
      if (location.pathname.match(path)) {
        isOkayToAccessReadOnly = true;
      }
    });

    if (!isOkayToAccessReadOnly) {
      return <Unauthorized error={{ code: 403 }} />;
    }
  } else {
    let existRoleAdmin;

    if (Array.isArray(userRoles)) {
      existRoleAdmin = userRoles?.find(
        (item) =>
          item.role.role === 'admin' ||
          item.role.role === 'accounting' ||
          item.role.role === 'warehouse'
      );
    }

    let isPathProtectedForAdmins = false;

    pathsOnlyAdminAccess.forEach((path) => {
      if (location.pathname.match(path)) {
        isPathProtectedForAdmins = true;
      }
    });

    if (isPathProtectedForAdmins && !existRoleAdmin) {
      return <Unauthorized error={{ code: 403 }} />;
    }
  }

  // let existRoleAdmin;
  //
  // if (Array.isArray(userRoles)) {
  //   existRoleAdmin = userRoles?.find(
  //     (item) =>
  //       item.role.role === 'admin' ||
  //       item.role.role === 'accounting' ||
  //       item.role.role === 'warehouse'
  //   );
  // }
  //
  // let isPathProtectedForAdmins = false;
  //
  // pathsOnlyAdminAccess.forEach((path) => {
  //   if (location.pathname.match(path)) {
  //     isPathProtectedForAdmins = true;
  //   }
  // });
  //
  // if (isPathProtectedForAdmins && !existRoleAdmin) {
  //   return <Unauthorized error={{ code: 403 }} />;
  // }

  return children;
};

const NotAuthComponent = ({ children }) => {
  const { auth, status } = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  // should redirect to /login
  useEffect(() => {
    if (auth) {
      navigate(`/${params.lang || ''}`);
    }
  }, [auth]);

  if (status === 'idle') {
    return <LoadingPage />;
  }
  return children;
};

const LoadAuthComponent = ({ children }) => {
  const mounted = useRef(false);
  const { load, status } = useAuth({
    shouldUpdate: {
      toJSON: () => {
        return 'loadauth-route';
      },
      test: (prev, next) => {
        if (
          (next.status === 'authenticated' ||
            next.status === 'not-authenticated' ||
            next.status === 'error') &&
          prev.status !== next.status
        ) {
          return true;
        }
        return false;
      },
    },
  });

  // console.log(status, 'status');

  useEffect(() => {
    if (status === 'idle' && mounted.current && mounted.current === 6) {
      // console.log('loading auth');
      load();
    }
    mounted.current = true;
  }, [status]);

  return children;
};

const isNotAuthenticated = (Component) => {
  const finalComp = () => {
    // should redirect to /login
    return (
      <LoadAuthComponent>
        <NotAuthComponent>
          <Component />
        </NotAuthComponent>
      </LoadAuthComponent>
    );
  };
  finalComp['@@redial-hooks'] = Component['@@redial-hooks'];
  return finalComp;
};
const isAuthenticated = (Component, { blockUnloggedUsers } = {}) => {
  const finalComp = memo(() => {
    return (
      <LoadAuthComponent>
        <AuthComponent blockUnloggedUsers={blockUnloggedUsers}>
          <Component />
        </AuthComponent>
      </LoadAuthComponent>
    );
  });
  finalComp['@@redial-hooks'] = Component['@@redial-hooks'];
  return finalComp;
};
const loadAuth = (Component) => {
  const finalComp = memo(() => (
    <LoadAuthComponent>
      <Component />
    </LoadAuthComponent>
  ));
  finalComp['@@redial-hooks'] = Component['@@redial-hooks'];
  return finalComp;
};

const AdminAuth = isAuthenticated(Admin);
const LoginSuccessAuth = isAuthenticated(LoginSuccess);
const DocsAuth = Docs;
const RegisterAuth = isNotAuthenticated(Register);
const AppAuth = loadAuth(App);

const routes = [
  {
    element: <AppAuth />,
    path: '/',
    children: [
      // { path: '/', element: redirect },
      { path: '/', element: <Home /> },
      { path: '/home2', element: <Home2 /> },
      {
        path: '/admin',
        element: <AdminAuth />,
        children: [
          {
            path: 'dashboard',
            element: <AdminDashboard />,
          },
          {
            path: 'internal-dashboard',
            element: <AdminInternalDashboard />,
          },
          {
            path: 'users',
            element: <AdminUsers />,
          },
          {
            path: 'new-user',
            element: <AdminNewUser />,
          },
          {
            path: 'users/edit/:id',
            element: <AdminNewUser />,
          },
          {
            path: 'accounts',
            element: <AdminAccounts />,
          },
          {
            path: 'accounts/:status',
            element: <AdminAccounts />,
          },
          {
            path: 'accounts/edit/:id',
            element: <AdminNewAccount />,
          },
          {
            path: 'new-account',
            element: <AdminNewAccount />,
          },
          {
            path: 'white-labels',
            element: <AdminWhiteLabel />,
          },
          {
            path: 'parcels',
            element: <AdminParcels />,
          },
          {
            path: 'parcels-v2',
            element: <AdminParcelsV2 />,
          },
          {
            path: 'duties-cost-calculator',
            element: <DutiesCostCalculator />,
          },
          {
            path: 'unitizers-dispatches-report',
            element: <UnitizersDispacthesReportLoadable />,
          },
          {
            path: 'dispatches-report',
            element: <DispacthesReportLoadable />,
          },
          {
            path: 'parcels/taxed-pending',
            element: <AdminParcelsTaxedPending />,
          },
          {
            path: 'parcels/taxed-paid',
            element: <AdminParcelsTaxedPaid />,
          },
          {
            path: 'parcels/:status',
            element: <AdminParcels />,
          },
          {
            path: 'pickups/:status',
            element: <AdminPickups />,
          },
          {
            path: 'new-pickup',
            element: <AdminNewPickup />,
          },
          {
            path: 'pickups/scan-pickup',
            element: <AdminScanPickup />,
          },
          {
            path: 'new-pickup-v2',
            element: <AdminNewPickupV2 />,
          },
          {
            path: 'new-pickup/:id',
            element: <AdminNewPickup />,
          },
          {
            path: 'edit-pickup/:pickupId',
            element: <AdminNewPickup />,
          },
          {
            path: 'parcel-imports',
            element: <AdminParcelImports />,
          },
          {
            path: 'parcel-imports-dl-packet',
            element: <AdminParcelDLPacketImports />,
          },
          {
            path: 'parcel-scan',
            element: <AdminParcelScanV3 />,
          },
          {
            path: 'parcel-scanv2',
            element: <AdminParcelScanV3 />,
          },
          {
            path: 'origin-scan',
            element: <AdminOriginScan />,
          },
          {
            path: 'parcel-tools',
            element: <AdminParcelTools />,
          },
          {
            path: 'parcels-undeliverable',
            element: <AdminParcelsUndeliverable />,
          },
          {
            path: 'parcels-undeliverable/:status',
            element: <AdminParcelsUndeliverable />,
          },
          {
            path: 'tracking-events',
            element: <AdminTrackingEvents />,
          },
          {
            path: 'rates-calculator',
            element: <AdminRatesCalculator />,
          },
          {
            path: 'edit-parcel',
            element: <AdminNewParcel />,
          },
          {
            path: 'edit-parcel/:id',
            element: <AdminNewParcel />,
          },
          {
            path: 'edit-parcel-service/:id',
            element: <EditParcelService />,
          },
          {
            path: 'label-parcel/:id',
            element: <AdminDonwloadLabelParcel />,
            // element: <AdminDonwloadLabelParcelForScan />,
          },
          {
            path: 'label-parcel-scan/:id',
            element: <AdminDonwloadLabelParcelForScan />,
          },
          {
            path: 'label-pickup/:id',
            element: <AdminDonwloadLabelPickup />,
          },
          {
            path: 'cn33-receptacle-list',
            element: <AdminCN33ReceptacleList />,
          },
          {
            path: 'cn33-receptacle-list-v2',
            element: <AdminCN33ReceptacleListV2 />,
          },
          {
            path: 'cn33-receptacle-list/:id',
            element: <AdminCN33ReceptacleList />,
          },
          {
            path: 'receptacle-list',
            element: <AdminReceptacleList />,
          },
          {
            path: 'receptacle/:id',
            element: <AdminEditReceptacle />,
          },
          {
            path: 'receptacle-dashboard',
            element: <AdminReceptacleDashboard />,
          },
          {
            path: 'receptacle/new',
            element: <AdminNewReceptacle />,
          },
          {
            path: 'receptacle-type-list',
            element: <AdminReceptacleTypeList />,
          },
          {
            path: 'new-receptacle-type',
            element: <AdminNewReceptacleType />,
          },
          {
            path: 'receptacle-type/edit/:id',
            element: <AdminNewReceptacleType />,
          },
          {
            path: 'label-unitizer/:id',
            element: <AdminDonwloadLabelUnitizer />,
          },
          {
            path: 'cn33-load-receptacle',
            element: <AdminCN33LoadReceptacle />,
          },
          {
            path: 'unitizers-informed',
            element: <AdminCN33UnitizersInformed />,
          },
          {
            path: 'dispatch-list',
            element: <AdminDispatchList />,
          },
          {
            path: 'edit-dispatch/:id',
            element: <AdminEditDispatch />,
          },
          {
            path: 'load-dispatch',
            element: <AdminLoadDispatch />,
          },
          {
            path: 'dispatches-pending',
            element: <AdminDispatchesPending />,
          },
          {
            path: 'label-dispatch/:id',
            element: <AdminDonwloadLabelDispatch />,
          },
          {
            path: 'airway-bills',
            element: <AdminAirwayBills />,
          },
          {
            path: 'airway-bill/:id',
            element: <AdminAirwayBillNew />,
          },
          {
            path: 'dispatch-shipped',
            element: <AdminDispatchShipped />,
          },
          {
            path: 'assign-airwaybills',
            element: <AssignAirwaybills />,
          },
          {
            path: 'service-deposits',
            element: <ServiceDeposits />,
          },
          {
            path: 'list-pricing',
            element: <ListPricing />,
          },
          {
            path: 'compose-weights/:type',
            element: <ComposeWeights />,
          },
          {
            path: 'compose-pricing',
            element: <ComposePricing />,
          },
          {
            path: 'compose-costs',
            element: <ComposeCarrierCosts />,
          },
          {
            path: 'compose-costs-simple',
            element: <ComposeCarrierCostsSimple />,
          },
          {
            path: 'compose-costs-simple/:id',
            element: <ComposeCarrierCostsSimpleCreate />,
          },
          {
            path: 'compose-last-mile-costs',
            element: <ComposeLastMileCosts />,
          },
          {
            path: 'markup',
            element: <Markup />,
          },
          {
            path: 'services',
            element: <AdminServices />,
          },
          {
            path: 'internal-services',
            element: <AdminInternalServices />,
          },
          {
            path: 'internal-services-services',
            element: <AdminInternalServicesServices />,
          },
          {
            path: 'service-service-pickup',
            element: <AdminServiceServicePickup />,
          },
          {
            path: 'service-options',
            element: <AdminServiceOptions />,
          },
          {
            path: 'service-countries',
            element: <AdminServiceCountries />,
          },
          {
            path: 'service-routes',
            element: <AdminServiceRoutes />,
          },
          {
            path: 'service-route-legs',
            element: <AdminServiceRouteLegs />,
          },
          {
            path: 'airports',
            element: <AdminAirports />,
          },
          {
            path: 'airport-routes',
            element: <AdminAirportRoutes />,
          },
          {
            path: 'airports-offices',
            element: <AdminAirportsOffices />,
          },
          {
            path: 'airlines',
            element: <AdminAirlines />,
          },
          {
            path: 'rates-postalcodes',
            element: <RatesPostalCodes />,
          },
          {
            path: 'harmonized-codes-restrictions',
            element: <HarmonizedCodesRestrictions />,
          },
          {
            path: 'harmonized-codes-restrictions-auth',
            element: <HarmonizedCodesRestrictionsAuth />,
          },
          {
            path: 'harmonized-codes',
            element: <HarmonizedCodes />,
          },
          {
            path: 'rates-states',
            element: <RatesStates />,
          },
          {
            path: 'rates-countries',
            element: <RatesCountries />,
          },
          {
            path: 'rates-service-providers',
            element: <RatesServiceProviders />,
          },
          {
            path: 'rates-services',
            element: <RatesServices />,
          },
          {
            path: 'weights',
            element: <Weights />,
          },
          {
            path: 'money-ranges',
            element: <MoneyRanges />,
          },
          {
            path: 'tariffs',
            element: <Tariffs />,
          },
          {
            path: 'services-areas-tariffs',
            element: <ServicesAreasTariffs />,
          },
          {
            path: 'services-areas-weights-costs',
            element: <ServicesAreasWeightsCosts />,
          },
          {
            path: 'services-areas-insurance-costs',
            element: <ServicesAreasInsuranceCosts />,
          },
          {
            path: 'services-areas-surcharges-costs',
            element: <ServicesAreasSurchargesCosts />,
          },
          // {
          //   path: '/rates-tariffs-weights',
          //   element: <RatesTariffsWeights />,
          // },
          // {
          //   path: '/rates-services-areas',
          //   element: <RatesServicesAreas />,
          // },
          {
            path: 'rates-services-rules',
            element: <RatesServicesRules />,
          },
          {
            path: 'rates-services-rules-types',
            element: <RatesServicesRulesTypes />,
          },
          {
            path: 'rates-services-providers-destinations',
            element: <RatesServicesProvidersDestinations />,
          },
          {
            path: 'rates-services-providers-countries',
            element: <RatesServicesProvidersCountries />,
          },
          // {
          //   path: 'rates-rates',
          //   element: <RatesRates />,
          // },
          // {
          //   path: 'surchages-types',
          //   element: <SurchagesTypes />,
          // },
          // {
          //   path: 'surchages-airlines',
          //   element: <SurchagesAirlines />,
          // },
          // {
          //   path: 'surchages-tariffs',
          //   element: <SurchagesTariffs />,
          // },
          // {
          //   path: 'rates-billing-factors',
          //   element: <RatesBillingFactors />,
          // },
          // {
          //   path: 'rates-billing-tiers',
          //   element: <RatesBillingTiers />,
          // },
          {
            path: 'airway-bill',
            element: <AdminAirwayBill />,
          },
          {
            path: 'Admin/invoices/:id',
            element: <AdminInvoice />,
          },
          {
            path: 'invoices',
            element: <AdminInvoices />,
          },
          {
            path: 'reports-colombia-manifest',
            element: <AdminColombiaManifest />,
          },
          {
            path: 'reports',
            element: <AdminReports />,
          },
          {
            path: 'claims',
            element: <ClaimsContactForm />,
          },
          {
            path: 'login-logs',
            element: <AdminLoginLogs />,
          },
          {
            path: 'quickbooks',
            element: <AdminQuickbooks />,
          },
          { path: 'tracking-codes-vendors', element: <AdminTrackingCodesVendors /> },
          { path: 'tracking-codes', element: <AdminTrackingCodes /> },
          { path: 'tracking-codes-customers', element: <AdminTrackingCodesCustomers /> },
          { path: 'store-orders', element: <AdminStoreOrders /> },
          { path: 'stores', element: <AdminStores /> },
          { path: 'stores/new', element: <AdminNewStore /> },
          { path: 'stores/edit/:id', element: <AdminNewStore /> },
          { path: 'stores/amazon/authorization', element: <AdminStoreAmazonAuthorization /> },
          { path: 'stores/amazon/authorization/:id', element: <AdminStoreAmazonAuthorization /> },
          { path: 'stores/amazon/orders', element: <AdminStoreAmazonOrders /> },
          { path: 'country-regulations', element: <AdminCountryRegulations /> },
          { path: 'good-categories', element: <AdminGoodCategories /> },
          { path: 'harmonized-codes-interchange', element: <HarmonizedCodesInterchange /> },
          {
            path: 'account-hs-code-categorization',
            element: <AdminAccountHsCodesCategorization />,
          },
          {
            path: 'account-service-restrictions',
            element: <AdminAccountServiceRestrictions />,
          },
          {
            path: 'account-service-price-limits',
            element: <AccountServicePriceLimits />,
          },
          {
            path: 'facilities',
            element: <AdminFacilities />,
          },
          {
            path: 'dropoff-stations',
            element: <AdminDropoffStations />,
          },
          {
            path: 'addresses',
            element: <AdminAddress />,
          },
          {
            path: 'address-details',
            element: <AdminAddressDetails />,
          },
          {
            path: 'bps-kpi',
            element: <BpsKpi />,
          },
          {
            path: 'customs-alerts',
            element: <CustomAlert />,
          },
          {
            path: 'cleaned-locations',
            element: <CleanedLocations />,
          },
          {
            path: 'facilities-inbound-rules',
            element: <AdminFacilitiesInboundRules />,
          },
          { path: '*', element: <NotFound /> },
        ],
      },
      { path: '/fulfilment', element: <Fulfilment /> },
      { path: '/docs', element: <DocsAuth /> },
      { path: '/about-us', element: <AboutUs /> },
      { path: '/privacy', element: <Privacy /> },
      { path: '/terms', element: <Terms /> },
      { path: '/prohibited', element: <Prohibited /> },
      { path: '/perfumes', element: <Perfumes /> },
      { path: '/lithium-batteries', element: <LithiumBatteries /> },
      { path: '/claims', element: <Claims /> },
      { path: '/login', element: <Login /> },
      // { path: '/new-account', element: <Login /> },
      {
        path: '/login/:type',
        element: <Login />,
      },
      {
        path: '/login/:type/*',
        element: <Login />,
        children: [{ path: ':slug', element: <Login /> }],
      },
      { path: '/login-success', element: <LoginSuccessAuth /> },
      { path: '/register', element: <RegisterAuth /> },
      { path: '/tracking', element: <Tracking /> },
      { path: '/tracking/:trackingNumber', element: <Tracking /> },
      { path: '/parcels/undeliverable', element: <ParcelsUndeliverable /> },
      { path: '/contact-us', element: <ContactUs /> },
      { path: '*', element: <NotFound /> },
    ],
  },
];

export default routes;
